export const QueriesKeys = {
    useProfileOwnerQuery: 'me',
    useAccessoriesQuery: 'accessories',
    useInfiniteAccessoriesQuery: 'accessories',
    useProfilesQuery: 'profiles',
    useInfiniteProfilesQuery: 'infinite-profiles',
    useProfilesSlugQuery: 'profile-slug',
    useAccessoryQuery: 'accessory',
    useAccessoryActivationSettings: 'accessory-activation-settings',
    usePersonalProfileQuery: 'personal-profile',
    useInfiniteConnections: 'connections-list',
    useProfileShareInfo: 'profile-share-info',
    useConnectionsStandardProfileQuery: 'connections-standard-profile',
    useLeadCaptureFormsQuery: 'lead-capture-forms',
    useUserMetadata: 'user-profile-metadata',
}

export const inputDefaultDebounceTime = 300

export const itemsPerPage = 30
